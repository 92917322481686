<template>
  <div class="course_detail">
    <div class="sticky_nav" v-show="showSticky">
      <div class="container">
        <div class="left_nav">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- 课程介绍 -->
            <el-tab-pane label="课程介绍" name="1"> </el-tab-pane>
            <el-tab-pane label="章节目录" name="2"> </el-tab-pane>
            <el-tab-pane :label="commentHead" name="3"> </el-tab-pane>
          </el-tabs>
        </div>
        <div class="btns">
          <div
            class="btn_bar"
            v-show="!isSchool && courseInfo.price > 0 && !courseInfo.buy"
          >
            <button class="buy" @click="goOrder(courseInfo.courseId)">
              立即购买
            </button>
            <button v-show="haveFree" class="try" @click="trySee">
              免费试看
            </button>
          </div>
          <div
            class="btn_bar"
            v-show="isSchool || courseInfo.price == 0 || courseInfo.buy"
          >
            <button class="buy" @click="goStudy(courseInfo.courseId)">
              立即学习
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- 面包屑导航 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/allCourses' }"
          >全部课程</el-breadcrumb-item
        >
        <el-breadcrumb-item
          class="jump_nav"
          v-for="item in navList"
          :key="item.categoryId"
          @click.native="jump(item)"
          >{{ item.name }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ courseInfo.title }}</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 课程简要信息 -->
      <div class="synopsis">
        <div
          v-if="!playVideo"
          class="left"
          @click="goStudy(courseInfo.courseId)"
        >
          <img :src="courseInfo.img" alt="" />
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#iconweibiaoti-1-42"></use>
          </svg>
        </div>
        <div v-else id="dplayer"></div>
        <div class="right">
          <div class="collect_star" @click="handleCollect">
            <i class="iconfont" v-if="!courseInfo.collect">&#xe617;</i>
            <i class="iconfont sel" v-else>&#xe618;</i>
            <p :class="{ collected: courseInfo.collect }">收藏</p>
          </div>
          <h2 class="course_title">{{ courseInfo.title }}</h2>
          <div class="tags">
            <span v-show="haveFree"
              ><i class="iconfont">&#xe624;</i>免费试学</span
            >
            <span><i class="iconfont">&#xe624;</i>资深教师</span>
            <span><i class="iconfont">&#xe624;</i>专业课程</span>
          </div>
          <div class="teachers">
            讲师：<span
              v-for="(teacher, idx) in courseInfo.teacherList"
              :key="teacher.teacherId"
              >{{ teacher.name
              }}<span v-show="idx + 1 < courseInfo.teacherList.length"
                >、</span
              ></span
            >
            <span class="already_study"
              >已学习{{ courseInfo.playCount }}人</span
            >
          </div>
          <div class="price" v-show="!isSchool && courseInfo.open == 1">
            <p v-show="courseInfo.price > 0">
              <span style="font-size: 18px">￥</span>{{ courseInfo.price }}
            </p>
            <p v-show="courseInfo.price == 0">免费</p>
          </div>
          <div
            class="btn_bar"
            v-show="!isSchool && courseInfo.price > 0 && !courseInfo.buy"
          >
            <button class="buy" @click="goOrder(courseInfo.courseId)">
              立即购买
            </button>
            <button v-show="haveFree" class="try" @click="trySee">
              免费试看
            </button>
          </div>
          <div
            class="btn_bar"
            v-show="isSchool || courseInfo.price == 0 || courseInfo.buy"
          >
            <button class="buy" @click="goStudy(courseInfo.courseId)">
              立即学习
            </button>
          </div>
        </div>
      </div>

      <!-- 课程信息 -->
      <div class="info_area">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 课程介绍 -->
          <el-tab-pane label="课程介绍" name="1">
            <section class="teacher_info">
              <p class="title">讲师介绍</p>
              <div class="teacher_slide">
                <div class="prev" @click="slidePrev" v-show="show">
                  <i class="iconfont">&#xe625;</i>
                </div>
                <div class="next" @click="slideNext" v-show="show">
                  <i class="iconfont">&#xe614;</i>
                </div>
                <div
                  ref="slide"
                  class="slide"
                  :style="{ left: distance + 'px' }"
                >
                  <div
                    class="one_teacher"
                    v-for="teacher in courseInfo.teacherList"
                    :key="teacher.teacherId"
                  >
                    <div
                      class="avatar"
                      @click="
                        showTeacher = true;
                        currentTeacher = teacher;
                      "
                    >
                      <img :src="teacher.icon" alt="" />
                    </div>
                    <div class="info">
                      <p>
                        <span
                          class="name"
                          @click="
                            showTeacher = true;
                            currentTeacher = teacher;
                          "
                          >{{ teacher.name }}</span
                        ><span class="tag">讲师</span>
                      </p>
                      <p class="desc">{{ teacher.info }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="course_info">
              <p class="title">课程简介</p>
              <div class="text" v-html="courseInfo.content"></div>
            </section>
          </el-tab-pane>

          <!-- 章节目录 -->
          <el-tab-pane label="章节目录" name="2">
            <div
              class="chapter_list"
              v-if="courseInfo.chapterList && courseInfo.chapterList.length > 0"
            >
              <p style="padding: 22px 0; border-bottom: solid 1px #eeeeee">
                章节目录（共{{ courseInfo.chapterCount }}章）
              </p>
              <el-collapse :value="chapterTree.map((c) => c.chapterId)">
                <el-collapse-item
                  v-for="item in chapterTree"
                  :key="item.chapterId"
                  :title="item.title"
                  :name="item.chapterId"
                >
                  <div
                    class="chapter"
                    :class="{ disable: !chapter.open }"
                    v-for="chapter in item.children"
                    :key="chapter.chapterId"
                    @click="
                      chapter.open && goStudy(courseInfo.courseId, chapter)
                    "
                  >
                    <!-- <span class="index">第{{ idx + 1 }}章</span> -->
                    <span class="title">{{ chapter.title }}</span>
                    <div class="right_info">
                      <i
                        v-if="!chapter.open"
                        class="el-icon-lock"
                        style="font-size: 16px"
                      />
                      <template v-else>
                        <span
                          class="free_tag"
                          v-show="chapter.free == true && !isSchool && courseInfo.open == 1"
                          >免费试看</span
                        >
                        <span class="video_time" v-show="chapter.videoId"
                          >{{ formatVideoTime(chapter.videoTime)
                          }}<i class="iconfont">&#xe62f;</i></span
                        >
                        <span class="no_video" v-show="!chapter.videoId">
                          无视频
                          <i class="iconfont" title="老师暂未上传视频哦"
                            >&#xe615;</i
                          >
                        </span>
                      </template>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>

            <!-- 空状态 -->
            <empty-status
              v-else
              :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
              desc="暂无章节~"
            />
          </el-tab-pane>

          <!-- 评论 -->
          <el-tab-pane :label="commentHead" name="3" class="comment_tab">
            <div class="comment_area">
              <div class="star">
                <span class="t">我要评价：</span>
                <el-rate
                  class="pay_star"
                  v-model="appraiseParams.star"
                  text-color="#FBB925"
                  :colors="colors"
                  @change="giveStar"
                ></el-rate>
              </div>
              <div class="input_area" v-show="showComment">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  placeholder="说说您的评价吧~"
                  v-model="appraiseParams.content"
                  resize="none"
                >
                </el-input>
                <div class="btn_bar">
                  <button
                    class="btn cancel"
                    @click="
                      showComment = false;
                      appraiseParams.star = 0;
                      appraiseParams.content = '';
                    "
                  >
                    取消
                  </button>
                  <button class="btn submit" @click="addAppraise">提交</button>
                </div>
              </div>
            </div>
            <courseComment
              :id="$route.query.courseId"
              :key="timer"
              @getCommentHead="getCommentHead"
            />
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 猜你喜欢 -->
      <div class="guess_like">
        <p class="section_title">猜你喜欢</p>
        <div>
          <one-lesson
            class="one_lesson"
            v-for="lesson in gussLikeList"
            :key="lesson.courseId"
            :lessonInfo="lesson"
            width="264"
            height="164"
            @click.native="goDetail(lesson.courseId)"
          />
        </div>
      </div>
    </div>

    <el-dialog
      class="teacher_popup"
      title="查看讲师"
      :visible.sync="showTeacher"
      width="500px"
    >
      <div style="overflow: hidden">
        <div class="avatar">
          <img :src="currentTeacher.icon" alt="" />
        </div>
        <div class="info">
          <p class="name">{{ currentTeacher.name }}</p>
          <p class="tag">讲师</p>
        </div>
      </div>
      <p class="desc">{{ currentTeacher.info }}</p>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCourseDetail,
  addCollect,
  delCollect,
  addAppraises,
  videoAddress,
} from "@/api/curriculum";
import { addOrder } from "@/api/curriculum";
import { getInterst } from "@/api/index";
import oneLesson from "@/components/oneLesson/index";
import courseComment from "./courseComment";
import { formatVideoTime } from "@/utils/index";
import DPlayer from "dplayer";
import emptyStatus from "@/components/emptyStatus/index";
import { handleTree } from "@/utils/treeShape";
export default {
  name: "courseDetail",
  components: {
    oneLesson,
    courseComment,
    emptyStatus,
  },
  created() {
    this.getCourse();
    this.getInterstList();
    if (this.$route.query.idx) {
      this.activeName = String(this.$route.query.idx);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {
    isSchool() {
      return this.$store.state.currentEnvironment;
    },
    categorys() {
      return this.$store.state.classificationOrgin;
    },
    // 是否有免费章节
    haveFree() {
      return this.courseInfo.chapterList
        ? this.courseInfo.chapterList.some((item) => {
            return item.free == true;
          })
        : false;
    },
    chapterTree() {
      let list = this.courseInfo.chapterList || [];
      list = list.map((item) => ({
        ...item,
        parentId: item.type == 1 ? 0 : item.parentId || 0,
      }));
      const tree = handleTree(list, "chapterId");
      return tree;
    },
  },
  methods: {
    // 监听滚定条
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 450) {
        this.showSticky = true;
      } else {
        this.showSticky = false;
      }
    },
    // 课程信息
    getCourse() {
      getCourseDetail(this.$route.query.courseId).then((res) => {
        if (res.code == 200) {
          this.courseInfo = res.data;
          if (res.data.teacherList.length > 3) this.show = true;
          if (this.first) {
            this.findCategory(res.data.categoryIds.split(",").slice(1, -1));
          }
        } else {
          this.$message.error(res.msg);
        }
        this.first = false;
      });
    },
    findCategory(arr) {
      this.categorys.forEach((item) => {
        arr.includes(String(item.categoryId)) && this.navList.push(item);
      });
    },
    // 面包屑跳转
    jump(ctx) {
      console.log(ctx);
      if (ctx.level == 3) {
        this.$router.push({
          path: "/allCourses",
          query: {
            mt: this.navList[0].categoryId,
            st: ctx.parentId,
            tt: ctx.categoryId,
          },
        });
      } else if (ctx.level == 2) {
        this.$router.push({
          path: "/allCourses",
          query: {
            mt: ctx.parentId,
            st: ctx.categoryId,
          },
        });
      } else {
        this.$router.push({
          path: "/allCourses",
          query: {
            mt: ctx.categoryId,
          },
        });
      }
    },
    // 点击收藏
    handleCollect() {
      if (this.courseInfo.collect) {
        //已收藏
        delCollect(this.$route.query.courseId).then((res) => {
          if (res.code == 200) {
            console.log("取消成功！");
            this.getCourse();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        // 未收藏
        addCollect(this.$route.query.courseId).then((res) => {
          if (res.code == 200) {
            console.log("收藏成功！");
            this.getCourse();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleClick(tab) {
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.idx = tab.name;
      this.$router.push({ path: this.$route.path, query });
    },
    // 评论标签文字（数量）
    getCommentHead(commentHead) {
      this.commentHead = commentHead;
    },
    slidePrev() {
      this.distance = this.distance + 343;
      if (Math.abs(this.$refs.slide.offsetLeft) < 343) this.distance = 0;
      if (this.$refs.slide.offsetLeft >= 0) return;
    },
    slideNext() {
      if (Math.abs(this.$refs.slide.offsetLeft) % 343 > 0) return;
      if (
        Math.abs(this.$refs.slide.offsetLeft) >
        this.$refs.slide.scrollWidth - 1136
      )
        return;
      this.distance = this.distance - 343;
    },
    // 点击评价星星
    giveStar() {
      this.showComment = true;
    },
    // 提交评论
    addAppraise() {
      addAppraises(this.appraiseParams).then((res) => {
        if (res.code == 200) {
          this.$message.success("评论成功！");
          this.timer = new Date().getTime();
          this.showComment = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 立即购买
    goOrder(courseId) {
      this.$router.push({ path: "/order", query: { courseId } });
    },
    goDetail(courseId) {
      let newUrl = this.$router.resolve({
        path: "/courseDetail",
        query: { courseId },
      });
      window.open(newUrl.href, "_blank");
    },
    goStudy(courseId, chapter) {
      if (this.courseInfo.chapterList.length <= 0) {
        this.$message.error("本课程暂无章节！");
        return;
      }

      if (this.courseInfo.buy) {
        this.goVideo(courseId, chapter);
      } else if (this.courseInfo.price == 0) {
        addOrder({ courseId }).then((res) => {
          if (res.code == 200) {
            this.goVideo(courseId, chapter);
          }
        });
      } else if (!chapter) {
        this.$confirm("您暂未购买课程，是否购买课程？", {
          confirmButtonText: "去购买",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.goOrder(courseId);
          })
          .catch(() => {});
      } else {
        if (!chapter.free) {
          this.$confirm("该章节属于收费章节，是否购买课程？", {
            confirmButtonText: "去购买",
            cancelButtonText: "取消",
          })
            .then(() => {
              this.goOrder(courseId);
            })
            .catch(() => {});
        } else {
          this.goVideo(courseId, chapter);
        }
      }

      // // 已购买和学校版本或者免费直接进入视频页面
      // if (this.courseInfo.buy || this.isSchool || this.courseInfo.price == 0) {
      //   this.goVideo(courseId, chapter);
      // } else if (!chapter) {
      //   if (this.courseInfo.chapterList[0].free) {
      //     this.goVideo(courseId);
      //   } else {
      //     this.$confirm("您暂未购买课程，是否购买课程？", {
      //       confirmButtonText: "去购买",
      //       cancelButtonText: "不购买",
      //     })
      //       .then(() => {
      //         this.goOrder(courseId);
      //       })
      //       .catch(() => {});
      //   }
      // } else {
      //   // 章节目录进入并且不是学校版本先判断是否免费
      //   if (chapter.chapterId && !this.isSchool) {
      //     if (!chapter.free) {
      //       this.$confirm("该章节属于收费章节，是否购买课程？", {
      //         confirmButtonText: "去购买",
      //         cancelButtonText: "不购买",
      //       })
      //         .then(() => {
      //           this.goOrder(courseId);
      //         })
      //         .catch(() => {});
      //     } else {
      //       this.goVideo(courseId, chapter);
      //     }
      //   }
      // }
    },
    // 跳转视频详情页
    goVideo(courseId, chapter) {
      this.$router.push({
        path: "/courseVideo",
        query: {
          courseId,
          chapterId: chapter
            ? chapter.chapterId
            : this.chapterTree[0].children[0].chapterId,
          index: chapter ? 2 : 1,
        },
      });
    },
    // 点击免费试看
    trySee() {
      this.trySeeChapter = this.courseInfo.chapterList.find((item) => {
        return item.free == true && item.videoId;
      });
      if (!this.trySeeChapter) {
        this.$message.error("免费章节暂无视频！");
        return;
      }
      videoAddress(this.trySeeChapter.chapterId).then((res) => {
        if (res.code === 200) {
          let url = res.data.playUrl.AUTO;
          this.playVideo = true;
          this.$nextTick(() => {
            this.dp = new DPlayer({
              container: document.getElementById("dplayer"),
              autoplay: true,
              video: {
                type: "hls",
                url,
              },
            });
          });
        } else {
          this.playVideo = false;
          this.$message.error(res.msg);
        }
      });
    },
    // 获取猜你喜欢
    getInterstList() {
      getInterst(this.interstParams).then((res) => {
        if (res.code == 200) {
          // console.log(res.rows);
          this.gussLikeList = res.rows;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  data() {
    return {
      formatVideoTime,
      first: true,
      courseInfo: {},
      navList: [],
      interstParams: {
        pageSize: 4,
      },
      gussLikeList: [],
      activeName: "1",
      commentHead: "评价",
      distance: 0,
      show: false,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      showComment: false,
      // 评论参数
      appraiseParams: {
        star: 0,
        content: "",
        courseId: this.$route.query.courseId,
      },
      timer: "",
      showSticky: false, // 是否显示吸顶导航
      showTeacher: false,
      currentTeacher: {},
      trySeeChapter: {},
      dp: {},
      playVideo: false,
    };
  },
};
</script>

<style lang="less" scoped>
.course_detail {
  min-height: calc(100vh - 66px - 200px);
  background-color: #f3f3f3;
  .sticky_nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 66px;
    line-height: 66px;
    background-color: #fff;
    z-index: 1000;
    overflow: hidden;
    .left_nav {
      float: left;
      ::v-deep.el-tabs {
        .el-tabs__active-bar {
          width: 60px !important;
          background-color: #0d79ff;
        }
        .el-tabs__item {
          color: #333;
          font-size: 16px;
          padding-right: 60px;
          &:hover,
          &.is-active {
            color: #0d79ff;
          }
        }
        .el-tabs__nav-wrap::after {
          display: none;
        }
      }
    }
    .btns {
      float: right;
      button {
        width: 120px;
        height: 40px;
        border-radius: 20px;
        &.buy {
          color: #fff;
          background: #ff8a22;
        }
        &.try {
          margin-left: 13px;
          color: #ff8a22;
          background: #fff7f0;
          border: 1px solid #ff8a22;
        }
      }
    }
  }
  ::v-deep.el-breadcrumb {
    padding: 22px 0;
    .jump_nav {
      .el-breadcrumb__inner {
        font-weight: 700;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: #303133;
        cursor: pointer;
        &:hover {
          color: #409eff;
        }
      }
    }
  }
  .synopsis {
    padding: 26px;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    .left {
      float: left;
      position: relative;
      width: 484px;
      height: 271px;
      cursor: pointer;
      z-index: 22;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.4;
        z-index: 33;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .iconfont {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 56px;
        height: 56px;
        z-index: 34;
      }
    }
    #dplayer {
      float: left;
      width: 484px;
      height: 271px;
    }
    .right {
      float: left;
      position: relative;
      margin-left: 24px;
      width: calc(100% - 484px - 24px);
      height: 271px;
      text-align: left;
      .collect_star {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        text-align: center;
        .iconfont {
          font-size: 20px;
          &.sel {
            color: #0d79ff;
          }
        }
        p {
          color: #999;
          font-size: 12px;
          &.collected {
            color: #0d79ff;
          }
        }
      }
      .course_title {
        max-width: 90%;
        font-size: 22px;
        font-weight: normal;
      }
      .tags {
        margin: 10px 0 15px;
        span {
          margin-right: 20px;
          font-size: 14px;
          color: #666;
          .iconfont {
            margin-right: 5px;
            color: #0d79ff;
            font-size: 16px;
          }
        }
      }
      .teachers {
        font-size: 14px;
        color: #999;
        .already_study {
          margin-left: 18px;
        }
      }
      .price {
        margin-top: 18px;
        font-size: 24px;
        color: #ff8a22;
      }
      .btn_bar {
        position: absolute;
        left: 0;
        bottom: 13px;
        button {
          width: 120px;
          height: 40px;
          border-radius: 20px;
          &.buy {
            color: #fff;
            background: #ff8a22;
          }
          &.try {
            margin-left: 13px;
            color: #ff8a22;
            background: #fff7f0;
            border: 1px solid #ff8a22;
          }
        }
      }
    }
  }

  .info_area {
    margin-top: 18px;
    background-color: #fff;
    border-radius: 6px;
    ::v-deep.el-tabs {
      .el-tabs__header {
        margin: 0;
        .el-tabs__nav-wrap {
          &::after {
            height: 1px;
            background: #eeeeee;
          }
        }
      }
      .el-tabs__nav-scroll {
        padding-left: 48px;
      }
      .el-tabs__active-bar {
        background-color: #0d79ff;
      }
      .el-tabs__item {
        height: 52px;
        line-height: 52px;
        font-size: 18px;
        padding-right: 80px;
        &:hover,
        &.is-active {
          color: #0d79ff;
        }
      }
      .el-tabs__content {
        min-height: 378px;
        .teacher_info,
        .course_info {
          padding: 0 32px;
          &.teacher_info {
            height: 117px;
          }
          &.course_info {
            padding-bottom: 30px;
            .text {
              text-align: left;
            }
          }
          .title {
            margin: 22px 0;
            font-size: 14px;
            color: #999;
            text-align: left;
          }
          .teacher_slide {
            position: relative;
            width: 100%;
            text-align: left;
            white-space: nowrap;
            overflow-x: auto;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            &::-webkit-scrollbar {
              width: 0 !important;
            }
            .slide {
              position: relative;
              height: 100%;
              transition: all 0.5s ease;
            }
            .prev,
            .next {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 24px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              background-color: rgba(0, 0, 0, 0.4);
              border-radius: 50%;
              color: #fff;
              cursor: pointer;
              user-select: none;
              z-index: 33;
              &.prev {
                left: 0;
              }
              &.next {
                right: 0;
              }
            }
            .one_teacher {
              display: inline-block;
              margin-right: 40px;
              overflow: hidden;
              .avatar {
                float: left;
                margin-right: 12px;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                overflow: hidden;
                cursor: pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .info {
                float: left;
                width: 235px;
                .name {
                  font-size: 16px;
                  color: #333;
                  cursor: pointer;
                }
                .tag {
                  margin-left: 10px;
                  display: inline-block;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  background: #fff7ed;
                  color: #f39526;
                  border: 1px solid #f39526;
                  border-radius: 10px;
                }
                .desc {
                  margin-top: 10px;
                  font-size: 14px;
                  color: #999;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
        .chapter_list {
          padding: 0 33px 40px;
          text-align: left;
          .disable {
            cursor: default !important;
            color: #ccc;
            &:hover {
              background: none !important;
            }
          }
          .chapter {
            margin-left: 20px;
            padding: 20px 0;
            font-size: 14px;
            border-bottom: solid 1px #eeeeee;
            transition: all 0.5s ease;
            cursor: pointer;
            &:hover {
              background-color: #f6f6f6;
            }
            .index {
              margin-right: 34px;
            }
            .title {
              display: inline-block;
              max-width: 75%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              vertical-align: top;
            }
            .right_info {
              float: right;
              .free_tag {
                display: inline-block;
                margin-right: 20px;
                width: 70px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                font-size: 12px;
                color: #ff8a22;
                background: #fff7f0;
                border-radius: 14px;
              }
              .video_time {
                font-size: 14px;
                color: #999;
                .iconfont {
                  margin-left: 7px;
                }
              }
              .no_video {
                font-size: 12px;
                color: #999;
              }
            }
          }
        }
        .comment_tab {
          padding: 0 38px 0 27px;
        }
        .comment_area {
          padding: 33px 0;
          .star {
            padding-bottom: 30px;
            .t {
              margin-right: 15px;
              font-size: 14px;
              color: #999;
            }
            .pay_star {
              display: inline-block;
              .el-rate__item {
                .el-rate__icon {
                  font-size: 34px;
                }
              }
            }
          }
          .input_area {
            .btn_bar {
              margin-top: 15px;
              text-align: right;
              .btn {
                width: 96px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                border-radius: 18px;
                &.cancel {
                  color: #666;
                  background-color: #fff;
                  border: 1px solid #e7e7e7;
                }
                &.submit {
                  margin-left: 14px;
                  color: #fff;
                  background: #0d79ff;
                }
              }
            }
          }
        }
      }
    }
  }

  .guess_like {
    margin-top: 18px;
    margin-bottom: 51px;
    padding: 40px 33px;
    background: #ffffff;
    border-radius: 6px;
    text-align: left;
    .section_title {
      margin-bottom: 20px;
      font-size: 24px;
      text-align: left;
    }
    .one_lesson {
      margin-right: 26px;
      &:last-child {
        margin: 0;
      }
    }
  }

  ::v-deep.teacher_popup {
    .el-dialog__header {
      padding: 18px;
      border-bottom: solid 1px #f2f2f2;
    }
    .el-dialog__body {
      min-height: 344px;
    }
    .avatar {
      float: left;
      margin-right: 13px;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      float: left;
      padding-top: 5px;
      width: 235px;
      .name {
        font-size: 18px;
        color: #333;
      }
      .tag {
        display: inline-block;
        margin-top: 5px;
        width: 50px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #fff7ed;
        color: #f39526;
        border: 1px solid #f39526;
        border-radius: 10px;
      }
    }
    .desc {
      margin-top: 25px;
      font-size: 14px;
      color: #666;
    }
  }
}
</style>