<template>
  <div :style="{ width: width + 'px' }" class="one_lesson">
    <!-- 封面 -->
    <div class="cover" :style="{ height: height + 'px' }">
      <img :src="lessonInfo.img" alt="" />
    </div>
    <!-- 信息 -->
    <div class="info">
      <!-- 标题 -->
      <p class="lesson_title">{{ lessonInfo.title }}</p>
      <!-- 标签 -->
      <div class="tags">
        <template v-if="lessonInfo.tags">
          <span
            class="tag"
            v-for="(tag, idx) in lessonInfo.tags.split(',')"
            :key="idx"
            >{{ tag }}</span
          >
        </template>
      </div>
      <div class="bottom_info" v-if="showBottom">
        <!-- 价格 -->
        <span class="price" v-show="lessonInfo.price == 0">{{lessonInfo.open == 2 ? '院校' : '免费'}}</span>
        <span class="price" v-show="lessonInfo.price > 0"
          >￥{{ lessonInfo.price }}</span
        >
        <!-- 学习人数 -->
        <span class="study_count">{{ lessonInfo.playCount }}人已学习</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oneLesson",
  props: {
    showBottom: {
      type: Boolean,
      default: true
    },
    // 课程
    lessonInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    width: {
      type: String,
      default: "222",
    },
    height: {
      type: String,
      default: "140",
    },
  },
};
</script>

<style lang="less" scoped>
.one_lesson {
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
  .cover {
    margin-bottom: 8px;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    width: 100%;
    .lesson_title {
      margin-bottom: 6px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tags {
      margin-bottom: 16px;
      min-height: 20px;
      text-align: left;
      .tag {
        display: inline-block;
        padding: 2px 3px;
        margin-right: 7px;
        background-color: #f1f1f1;
        color: #999;
        font-size: 12px;
      }
    }
    .bottom_info {
      font-size: 14px;
      overflow: hidden;
      .price {
        float: left;
        color: #ff8a22;
      }
      .study_count {
        color: #999;
        float: right;
      }
    }
  }
}
</style>