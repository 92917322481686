<template>
  <div class="course_comment">
    <div class="comments" v-if="totalAppraises > 0" v-loading="isLoading">
      <div class="comment" v-for="(item, index) in commentList" :key="index">
        <div class="avatar">
          <img v-if="item.icon" :src="item.icon" alt />
          <img v-else src="@/assets/img/defaultavatar.png" alt />
        </div>
        <div class="cont">
          <div class="top">
            <span class="name" v-if="item.nickName">{{ item.nickName }}</span>
            <span class="name" v-else>游客</span>
            <el-rate
              class="star"
              v-model="item.star"
              disabled
              text-color="#FBB925"
            ></el-rate>
          </div>
          <div class="com">{{ item.content }}</div>
          <p class="date">
            {{ formatDate(item.createTime, "YYYY-MM-DD HH:mm") }}
          </p>
        </div>
      </div>
      <div class="foot_page">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next, jumper"
          :page-size="appraisesParams.pageSize"
          :current-page.sync="appraisesParams.pageNum"
          :total="totalAppraises"
          @current-change="appraisesPageChange"
        ></el-pagination>
      </div>
    </div>

    <emptyStatus
      v-else
      :img="require('@/assets/img/emptyIcon/wuxiaoxi@2x.png')"
      desc="快来抢沙发吧~"
    />
  </div>
</template>

<script>
import { appraises } from "@/api/curriculum";
import { formatDate } from "@/utils/index";
import emptyStatus from "@/components/emptyStatus/index.vue";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    emptyStatus,
  },
  created() {
    this.getAppraises();
  },
  methods: {
    // 评论列表
    getAppraises() {
      this.isLoading = true;
      appraises(this.appraisesParams).then((res) => {
        if (res.code === 200) {
          this.commentList = res.rows;
          this.totalAppraises = res.total;
          this.commentHead = `评价（${res.total}）`;
          this.isLoading = false;
          this.$emit("getCommentHead", this.commentHead);
        } else {
          console.log(res.msg);
        }
      });
    },
    appraisesPageChange() {
      this.getAppraises();
    },
  },
  data() {
    return {
      formatDate,
      // 请求评论参数
      appraisesParams: {
        pageNum: 1,
        pageSize: 5,
        courseId: this.id,
      },
      totalAppraises: 0,
      isLoading: false,
      commentHead: "评价",
    };
  },
};
</script>

<style lang="less" scoped>
.course_comment {
  position: relative;
  min-height: 378px;
  border-top: solid 1px #e5e5e5;
  .comments {
    // padding: 0 48px 0 36px;
    .comment {
      padding: 23px 0 30px;
      border-bottom: solid 1px #e5e5e5;
      overflow: hidden;
      .avatar,
      .cont {
        float: left;
        &.avatar {
          margin-right: 13px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &.cont {
          margin-top: 6px;
          width: calc(100% - 49px);
          .top {
            text-align: left;
            .name {
              color: #999;
              font-size: 14px;
              &.name {
                display: inline-block;
                // min-width: 178px;
              }
            }
            .star {
              display: inline-block;
              margin-left: 25px;
              vertical-align: top;
            }
          }
          .com {
            margin-top: 20px;
            color: #333;
            font-size: 14px;
            text-align: left;
          }
          .date {
            margin-top: 20px;
            color: #bfbfbf;
            font-size: 12px;
            text-align: left;
          }
        }
      }
    }
  }
  .foot_page {
    margin: 55px 0;
    text-align: right;
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0d79ff;
    }
  }
}
</style>